@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.spnone {
  display: block;
}

@media only screen and (max-width: 834px) {
  .spnone {
    display: none !important;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  counter-reset: number 0;
  line-height: 2em;
  letter-spacing: 0.015em;
  color: #333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  background: url(../images/common/pt_3.png) center top;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  padding: 0 0 100px 0;
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 834px) {
  #builingual {
    padding-top: 65px !important;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 60px;
  }
}

.fixed {
  position: fixed !important;
  z-index: 11 !important;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .fixed .flxL {
    width: 65% !important;
  }
  .fixed .flxR p {
    display: none;
  }
}

#mainArea {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 640px) {
  #mainArea {
    width: 140% !important;
    margin-left: -20%;
  }
}

#mainArea #siteID {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mainArea #siteID a {
  height: 100%;
  opacity: 1;
  display: block;
}

@media only screen and (max-width: 640px) {
  #mainArea #siteID a {
    padding: 3% 0;
  }
}

#mainArea #siteID a img {
  height: inherit !important;
}

#mainArea img {
  vertical-align: middle;
}

#header {
  background: rgba(255, 255, 255, 0.7);
  z-index: 11;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 0 10px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  #header {
    padding: 0;
    display: block;
  }
}

#header .flxL {
  position: relative;
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  #header .flxL {
    width: 100%;
  }
}

#header .flxR {
  padding: 15px 0;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1200px) {
  #header .flxR {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 12;
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  #header .flxR {
    padding: 0;
    width: 90%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12;
  }
}

#header .flxR p {
  margin-right: 2%;
  width: auto;
  color: #b91e23;
  font-weight: 700;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  #header .flxR p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  #header .flxR p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  #header .flxR p {
    display: none;
  }
}

#hedadd {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 834px) {
  #hedadd {
    width: 80%;
  }
}

#hedadd .tel2 {
  padding: 14px 10px;
  width: 50%;
  background: #b91e23;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-size: 28px;
  font-size: 1.75vw;
}

@media only screen and (min-width: 1600px) {
  #hedadd .tel2 {
    font-size: 28px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hedadd .tel2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  #hedadd .tel2 {
    font-size: 18px;
  }
}

#hedadd .tel2 a {
  height: 100%;
  display: block;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hedadd .cont {
  width: 50%;
  margin-left: 2px;
  background: #b91e23;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1em;
}

#hedadd .cont a {
  padding: 14px 10px;
  height: 100%;
  display: block;
  color: #fff;
  font-size: 28px;
  font-size: 1.75vw;
}

@media only screen and (min-width: 1600px) {
  #hedadd .cont a {
    font-size: 28px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hedadd .cont a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  #hedadd .cont a {
    font-size: 18px;
  }
}

#hednav {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

#hednav .wrap {
  width: 100%;
  height: 100%;
}

#hednav .wrap .navwrap {
  height: 100%;
}

#hednav .wrap .navwrap #nav_global {
  height: 100%;
}

#hednav .wrap .navwrap #nav_global ul {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul {
    display: block;
  }
}

#hednav .wrap .navwrap #nav_global ul .navnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul .navnone {
    display: block;
  }
}

#hednav .wrap .navwrap #nav_global ul li {
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul li {
    padding: 0 !important;
  }
}

#hednav .wrap .navwrap #nav_global ul li a {
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1;
  color: #333 !important;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap .navwrap #nav_global ul li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  #hednav .wrap .navwrap #nav_global ul li a {
    padding: 40px 10px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1000px) {
  #hednav .wrap .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul li a {
    font-size: 18px;
    padding: 5% 0;
    background: none !important;
  }
}

@media only screen and (max-width: 640px) {
  #hednav .wrap .navwrap #nav_global ul li a {
    font-size: 18px;
    padding: 5% 0;
    background: none !important;
  }
}

#hednav .wrap .navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#hednav .wrap .navwrap #nav_global ul li ul {
  display: block;
  position: absolute;
  height: auto;
  left: -50px;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul li ul {
    position: static !important;
    display: block !important;
  }
}

#hednav .wrap .navwrap #nav_global ul li ul li {
  display: block;
  height: auto;
  width: 150px;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul li ul li {
    width: 100%;
    padding: 0;
  }
}

#hednav .wrap .navwrap #nav_global ul li ul li a {
  display: block;
  height: auto;
  background: rgba(255, 255, 255, 0.5);
  padding: 15px 10px;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #nav_global ul li ul li a {
    padding: 5% 0;
  }
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 10px;
    top: 11px;
    color: #fff;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  color: #000;
  z-index: 12;
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 11px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 60px;
    width: 100%;
    background: #000;
    position: fixed;
    z-index: 11;
    top: 0;
    margin: 0;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translatey(-240%);
    -ms-transform: translatey(-240%);
    transform: translatey(-240%);
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #93c2d8;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: #a0c6d8;
  }
  #hednav .wrap .navwrap #hed_info .store3 p {
    background: #93c2d8;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    height: auto;
    width: 70%;
    margin: auto !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    padding: 5% 0;
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: solid 1px #fff;
  }
  #hednav .wrap .navwrap #nav_global ul li:first-child {
    border-top: solid 1px #fff;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 1rem !important;
    color: #fff !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: block;
    margin-top: 5px;
    margin-left: 0;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#local-keyvisual {
  overflow: hidden;
  position: relative;
  background-position: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-title {
    width: 100%;
  }
}

#local-keyvisual #local-keyvisual-title h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-weight: 700;
  z-index: 1;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 230px 10px;
  display: block;
  line-height: 1.4em;
  font-size: 50px;
  font-size: 3.125vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 45px;
    padding: 100px 10px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 40px;
    padding: 150px 10px;
  }
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 20px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #333;
}

#mainArea2 {
  position: relative;
}

#mainArea2 #siteID {
  z-index: 10;
  position: absolute;
  left: 20px;
  top: 20px;
}

#mainArea2 #siteID a {
  display: block;
  width: 80%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea2 #siteID a {
    width: 60%;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea2 #siteID a {
    width: 40%;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #333;
  font-weight: 700;
}

footer#global_footer a {
  font-weight: 700;
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  padding: 100px 0 0;
}

@media only screen and (max-width: 834px) {
  #footer {
    padding: 50px 0 0;
  }
}

#footer .inner {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    padding: 0 10px;
  }
}

#footer .inner address {
  margin-bottom: 50px;
}

#footer .inner address span {
  display: block;
}

#footer .inner address .tel2 a {
  color: #333;
}

@media only screen and (max-width: 640px) {
  #footer #f_nav {
    display: none;
  }
}

#footer #f_nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#footer #copy {
  background: #b91e23;
  display: block;
  font-size: 16px;
  margin-top: 30px;
  color: #fff;
  line-height: 50px;
}

#footer #copy a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

#footer #copy a:hover {
  opacity: 0.6;
}

.cont_1wrap {
  background-image: url(../images/common/bg_1.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .cont_1wrap {
    background-attachment: inherit;
    background-size: contain;
    background: none;
  }
}

.cont_1wrap .cont_1 {
  background: rgba(255, 255, 255, 0.5);
  padding: 100px 10px;
}

@media only screen and (max-width: 834px) {
  .cont_1wrap .cont_1 {
    padding: 50px 10px;
  }
}

.cont_1wrap .midashi {
  position: relative;
  z-index: 1;
}

.cont_1wrap .txt {
  line-height: 2em;
  position: relative;
  z-index: 1;
}

.btn {
  position: relative;
  z-index: 1;
  max-width: 400px;
  width: 100%;
  margin: 50px auto 0;
}

@media only screen and (max-width: 834px) {
  .btn {
    display: block;
    width: 100%;
  }
}

.btn a {
  background: #b91e23;
  display: block;
  padding: 15px 10px;
  font-size: 18px;
  color: #fff;
  transition: 1s;
}

.btn a:hover {
  opacity: 1;
  background: #971e22;
}

.btn a i {
  margin-right: 7px;
}

.cont_2 {
  display: flex;
  justify-content: space-between;
  background: url(../images/common/pt_1.png) center top;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    display: block;
  }
}

.cont_2:nth-child(odd) {
  color: #fff;
  flex-direction: row-reverse;
  background: url(../images/common/pt_2.png) center top;
}

.cont_2:nth-child(odd) .flxL:after {
  content: "";
  background: url(../images/common/bg_3.png) no-repeat center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.cont_2 .flxL {
  position: relative;
  padding: 70px 5%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 640px) {
  .cont_2 .flxL {
    width: 100%;
    padding: 50px 5%;
  }
}

.cont_2 .flxL:after {
  content: "";
  background: url(../images/common/bg_2.png) no-repeat center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.cont_2 .flxL > div {
  z-index: 1;
  position: relative;
}

.cont_2 .flxL .txt {
  line-height: 2em;
}

.cont_2 .flxR {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_2 .flxR {
    width: 100%;
  }
}

.cont_2 .flxR .img {
  height: 100%;
}

.cont_2 .flxR .img img {
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.cont_3 {
  background: url(../images/common/pt_1.png) center top;
  position: relative;
  margin-top: 100px;
  padding: 100px 10px;
}

@media only screen and (max-width: 834px) {
  .cont_3 {
    margin-top: 50px;
    padding: 50px 10px;
  }
}

.cont_3 .inner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner {
    display: block;
  }
}

.cont_3 .inner .flxL {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.cont_3 .inner .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .cont_3 .inner .flxR {
    width: 100%;
  }
}

.newswrap {
  background: #eedcb3;
  padding: 30px;
  position: relative;
}

.newswrap:before {
  content: "";
  background: url(../images/common/bg_4.png) right top no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: -30px;
  top: 30px;
  right: 0;
  z-index: 0;
}

@media only screen and (max-width: 1200px) {
  .newswrap:before {
    bottom: 20px;
  }
}

.newswrap:after {
  content: "";
  background: url(../images/common/bg_5.png) left bottom no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -30px;
  right: 0;
  bottom: 0;
  left: 30px;
  z-index: 0;
}

@media only screen and (max-width: 1200px) {
  .newswrap:after {
    top: 20px;
  }
}

.newswrap .news {
  z-index: 1;
  position: relative;
  height: 620px;
  overflow-y: scroll;
  padding-right: 10px;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    height: 450px;
  }
}

.newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newswrap .news dl dt, .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_4 {
  display: flex;
  justify-content: space-between;
  background: #000;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    display: block;
  }
}

.cont_4 .flxL {
  padding: 70px 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(../images/common/cont_6top.png), url(../images/common/img_6.jpg);
  background-repeat: no-repeat,no-repeat;
  background-position: center top,left top;
}

@media only screen and (max-width: 834px) {
  .cont_4 .flxL {
    padding: 50px 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxL {
    width: 100%;
  }
}

.cont_4 .flxL .txt {
  line-height: 2em;
  position: relative;
  z-index: 1;
}

.cont_4 .flxL .txt span {
  display: block;
  margin-top: 20px;
}

.cont_4 .flxR {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxR {
    width: 100%;
  }
}

.cont_4 .flxR .mapwrap {
  height: 100%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .flxR .mapwrap {
    height: 400px;
  }
}

.cont_4 .flxR .mapwrap .map_area {
  height: 100%;
}

.btn2 {
  position: relative;
  z-index: 1;
  max-width: 300px;
  min-width: 300px;
  margin: 30px auto 0;
}

@media only screen and (max-width: 834px) {
  .btn2 {
    display: block;
    width: 100%;
  }
}

.btn2 span {
  display: block;
  border: 1px solid #868686;
  padding: 20px;
  font-size: 18px;
  color: #fff;
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .btn2 span {
    padding: 0;
  }
}

.btn2 span:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.btn2 span i {
  transform: scale(-1, 1);
  margin-right: 7px;
}

.btn2 a {
  display: block;
  padding: 20px;
  font-size: 18px;
  color: #fff;
  transition: 1s;
}

.btn3 {
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin: 30px auto 0;
}

@media only screen and (max-width: 834px) {
  .btn3 {
    display: block;
    width: 100%;
  }
}

.btn3 a {
  display: block;
  border: 1px solid #868686;
  padding: 20px 100px;
  font-size: 18px;
  color: #fff;
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .btn3 a {
    padding: 20px 10px;
  }
}

.btn3 a:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.btn3 a i {
  margin-right: 7px;
}

.aboutbox {
  margin-left: -1.96078% !important;
}

@media only screen and (max-width: 640px) {
  .aboutbox {
    margin-left: 0 !important;
  }
}

.aboutbox ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .aboutbox ul {
    display: block;
  }
}

.aboutbox ul li {
  float: none !important;
  width: 31.37255% !important;
  margin-left: 1.96078% !important;
  margin-bottom: 30px !important;
}

@media only screen and (max-width: 640px) {
  .aboutbox ul li {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.aboutbox ul li img {
  width: 100% !important;
  margin-bottom: 10px;
}

.aboutbox ul li h3 {
  font-size: 16px;
  font-weight: bold;
  color: #00559d;
  text-align: center;
}

@media only screen and (max-width: 834px) {
  .aboutbox ul li h3 {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .aboutbox ul li h3 {
    font-size: 17px;
    line-height: 1.7em;
  }
}

.bnr {
  margin: 70px 0 0;
}

@media only screen and (max-width: 1000px) {
  .bnr {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .bnr {
    margin: 20px 0 0;
    padding: 0;
  }
}

.bnr ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .bnr ul {
    display: block;
  }
}

.bnr ul li {
  width: 49%;
  margin-bottom: 2%;
}

@media only screen and (max-width: 640px) {
  .bnr ul li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.media {
  display: block !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  .media {
    padding: 0 10px;
  }
}

.media .sns_list {
  border: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row !important;
  flex-wrap: wrap;
  margin-left: -2.04082% !important;
}

@media only screen and (max-width: 834px) {
  .media .sns_list {
    margin-left: 0 !important;
    justify-content: space-between;
  }
}

.media .sns_list > div {
  border-bottom: 0;
  float: none !important;
  width: 17.95918% !important;
  margin-left: 2.04082% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .sns_list > div {
    margin-left: 0;
    width: 31% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .media .sns_list > div {
    margin-left: 0;
    width: 48% !important;
    margin-left: 0 !important;
  }
}

.media .sns_list > div img {
  width: 100% !important;
  margin-bottom: 10px;
}

.media .sns_list > div .sns_photo {
  padding: 0 !important;
  width: 100% !important;
}

.media .sns_list > div .sns_photo a {
  display: block;
}

.media .sns_list > div .sns_text {
  display: none;
}

/* title */
.midashi h1 {
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h1 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h1 {
    font-size: 20px;
  }
}

.midashi h2 {
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.midashi2 {
  padding-bottom: 2px;
  border-bottom: 1px solid #333;
}

.midashi2 h2 {
  border-bottom: 3px solid #333;
  padding-bottom: 10px;
  color: #333;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    line-height: 1.7em;
  }
}

.midashi2 h3 {
  border-bottom: 3px solid #333;
  padding-bottom: 10px;
  color: #333;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h3 {
    line-height: 1.7em;
  }
}

.midashi3 h2 {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  color: #333;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 30px;
    line-height: 1.7em;
  }
}

.midashi3 h2:first-letter {
  color: #ad0000;
}

.midashi4 h2 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h3 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi4 h3 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h3 {
    font-size: 20px;
  }
}

.midashi4 h4 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h4 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi4 h4 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h4 {
    font-size: 20px;
  }
}

.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .about {
    display: block;
  }
}

.about:nth-child(odd) {
  flex-direction: row-reverse;
}

.about .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .about .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.about .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.about .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .about .flxR {
    width: 100%;
  }
}

.imgshadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.imgshadow img {
  width: 100% !important;
}

.img100 img {
  width: 100% !important;
}

.menu2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .menu2 {
    display: block;
  }
}

.menu2 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .menu2 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.menu2 .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.menu2 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .menu2 .flxR {
    width: 100%;
  }
}

.menu2 .fee {
  color: #b91e23;
  font-size: 20px;
  font-weight: 700;
}

.menu3 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .menu3 {
    display: block;
  }
}

.menu3 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .menu3 .flxL {
    width: 100%;
  }
}

.menu3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .menu3 .flxR {
    width: 100%;
  }
}

.menu4 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .menu4 {
    display: block;
  }
}

.menu4 .box {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .menu4 .box {
    width: 100%;
  }
}

.party {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 100px 0;
  position: relative;
  padding: 70px 0;
}

@media only screen and (max-width: 1000px) {
  .party {
    padding: 30px 10px;
  }
}

@media only screen and (max-width: 834px) {
  .party {
    margin: 0 0 50px 0;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 640px) {
  .party {
    display: block;
  }
}

.party .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .party .flxL {
    width: 100%;
    margin-bottom: 50px;
  }
}

.party .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.party .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .party .flxR {
    width: 100%;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  background: url(../images/common/pt_2.png);
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  z-index: -1;
}

.shopinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .shopinfo {
    display: block;
  }
}

.shopinfo:nth-child(odd) {
  flex-direction: row-reverse;
}

.shopinfo .flxL {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .shopinfo .flxL {
    width: 100%;
  }
}

.shopinfo .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.shopinfo .flxR {
  width: 38%;
}

@media only screen and (max-width: 640px) {
  .shopinfo .flxR {
    width: 100%;
  }
}

.mediawrap2 {
  margin-bottom: 100px;
  margin-left: -2.43902% !important;
}

@media only screen and (max-width: 640px) {
  .mediawrap2 {
    margin-bottom: 50px;
    margin-left: 0 !important;
  }
}

.mediawrap2 .media2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media only screen and (max-width: 640px) {
  .mediawrap2 .media2 {
    display: block;
  }
}

.mediawrap2 .media2 .box {
  float: none !important;
  width: 30.89431% !important;
  margin-left: 2.43902% !important;
  margin-bottom: 30px !important;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .mediawrap2 .media2 .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.mediawrap2 .media2 .box img {
  width: 100% !important;
  margin-bottom: 10px;
}

.shopinfo2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px;
}

@media only screen and (max-width: 640px) {
  .shopinfo2 {
    margin-bottom: 50px;
    display: block;
  }
}

.shopinfo2 .flxL {
  width: 70%;
}

@media only screen and (max-width: 640px) {
  .shopinfo2 .flxL {
    width: 100%;
  }
}

.shopinfo2 .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.shopinfo2 .flxR {
  width: 28%;
}

@media only screen and (max-width: 640px) {
  .shopinfo2 .flxR {
    width: 100%;
  }
}

.flow .ttl {
  font-size: 20px;
  width: 30%;
}

.flow .ttl span {
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
  text-align: center;
  background: #00559d;
  color: #fff;
  padding: 10px 15px;
}

@media only screen and (max-width: 640px) {
  .flow .ttl span {
    display: block;
    margin-bottom: 15px;
  }
}

.flow td {
  border: 0 !important;
}

.waku {
  padding: 40px;
  color: #474747;
  background: whitesmoke;
  border: double 7px #b91e23;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .waku .flxR {
    margin-top: 50px;
  }
}

.picktxt {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 2em;
}

.picktxt ul li {
  padding: 5px 10px !important;
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 640px) {
  .comp dl {
    display: block !important;
  }
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .comp dl dt {
    width: 100% !important;
  }
}

.comp dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media only screen and (max-width: 640px) {
  .comp dl dd {
    padding: 0 0 15px 0 !important;
    width: 100% !important;
  }
}

.comp dl a {
  color: #b2b2b2;
}

@media only screen and (max-width: 640px) {
  .comp2 dl {
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dt {
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #b2b2b2;
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #8cafc0 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #8cafc0;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #8cafc0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -9px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #ccc solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #9fcfe6;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
}

@media only screen and (max-width: 640px) {
  .img100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .img100 .box {
    margin-bottom: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
